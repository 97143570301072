import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getFirestore, onSnapshot, doc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

// Initialize Cloud Firestore and get a reference to the service
const firestoreDb = getFirestore(firebaseApp);

export const getUserNotificationsToken = (serviceWorkerRegistration) =>
    getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
        serviceWorkerRegistration,
    });

export const onMessageHandler = (handler) => onMessage(messaging, handler);

export const onSnapshotHandler = (collectionName, documentId, handler) => {
    const docRef = doc(firestoreDb, collectionName, documentId);
    return onSnapshot(docRef, handler);
};
